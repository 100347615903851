import React from 'react'
import "./EsgEmpact.css"

import esgImageBlackThemeImage from "../../assets/esgImageBlackThemeImage.jpg"
import esImage from "../../assets/esgImage.jpg"
const EsgEmpact = () => {
    return (
        <>
            <div className='section_three'></div>

            <section className='esg_section px-0 px-md-4 py-5' id='esg'>
                <div className='container-fluid pb-3'>
                    <div className='text-center heading_style mb-5 pb-3'>
                        <span data-aos="fade-down">ESG & Impact</span>
                    </div>
                    <div className='row gy-4'>
                        <div className='col-md-6 col-12'>
                            <div className='esg_left' data-aos="fade-right">
                                <img src={esgImageBlackThemeImage} alt="esg" className="default" />
                                <img src={esImage} alt="esg" className="on_hover" />
                            </div>
                        </div>
                        <div className='col-md-6 col-12' data-aos="zoom-in">
                            <div className='esg_content ps-0 ps-md-2' >
                                <ul>
                                    <li>
                                        Global greenhouse gas emissions which cause global warming have continued to increase, with unsustainable energy use, land use and land-use change, lifestyles and patterns of consumption and production across regions, between and within countries, and among individuals.
                                    </li>
                                    <li>
                                        Climate change is a threat to human well-being and planetary health. There is a rapidly closing window of opportunity to secure a liveable and sustainable future for all. Climate resilient development integrates adaptation and mitigation to advance sustainable development for all.
                                    </li>
                                    <li>
                                        BlackHill Fund Management is focused on catalysing private investments for achieving sustainable growth and climate resilience in the areas of agriculture, food, climate, financial services, and consumer including rural consumer through BlackHill Fund I, its growth equity fund.
                                    </li>
                                    <li>

                                        BlackHill uses Environmental, Social and Governance (ESG) principles and standards to screen and monitor its investments.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EsgEmpact
