import React from 'react';
import './Footer.css';
import logo from "../../assets/logo.png"
import { MdOutlineArrowForwardIos, MdMail } from "react-icons/md"
import { FaMapMarkerAlt } from "react-icons/fa";
import linkdin from "../../assets/linkedin.png"
import logopowered from "../../assets/logo2.png"
import { GoDotFill } from "react-icons/go";
const Footer = () => {
    return (
        <footer className="container-fluid footer px-3 px-lg-5">
            <div className="footer-container row justify-content-between">
                <div className='col-lg-5 col-md-6 col-12 mb-4 mb-lg-0 '>
                    <div className="footer-section company-info">
                        <div data-aos="fade-down">
                            <img src={logo} alt="Dream Ladder Logo" className="logo" />
                        </div>
                        <span className='footer_logo_style'>BlackHill</span>
                        <p className='mt-2' data-aos="zoom-in">
                            BlackHill is a leading growth fund employing both venture capital and private equity strategies to generate returns and impact. We follow a disciplined approach to investing and value creation. In their previous avatars, the founders have produced stellar investment returns
                        </p>
                        <div className='menu'>
                            <div className='sub_menu my-1' data-aos="fade-right" data-aos-delay="50"><GoDotFill size={16} color='#FFFF11' /> <a>Category of AIF : 2</a></div>
                            <div className='sub_menu my-1' data-aos="fade-right" data-aos-delay="100"><GoDotFill size={16} color='#FFFF11' /> <a>SEBI Registration Number : IN/AIF2/24-25/1548</a></div>
                            <div className='sub_menu my-1' data-aos="fade-right" data-aos-delay="150"><GoDotFill size={16} color='#FFFF11' /> <a>Name of the Fund Manager : Rajesh Ranjan </a></div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='row d-flex'>
                        <div className='col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 '>
                            <div className="footer-section quick-links">
                                <h3 data-aos="fade-down">QUICK LINKS</h3>
                                <div className='menu'>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#about'>About US</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#services'>Focus Areas</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#investmentStrategy'>Investment Strategy</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#esg'>ESG & Impact</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#team'>Team</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#contact'>Contact Us</a></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-12 mb-4 mb-lg-0 '>
                            <div className="footer-section contact-us">
                                <h3 data-aos="fade-down">CONTACT US</h3>
                                <div className='menu'>

                                    <div className='sub_menu'><MdMail size={18} color='#FFFF11' /> <a>team@blackhillfund.com</a></div>
                                    <div className='sub_menu'><FaMapMarkerAlt size={18} color='#FFFF11' /> <a>502, Lodha Supremus,
                                        CT-05, Plot-8,
                                        New Cuffe Parade, Wadala East
                                        Mumbai 400037</a></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom_section py-3  pe-5'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-md-6 col-12'><span className='about_made'>Copyright © 2024 BlackHill Fund Management - All Rights Reserved.</span></div>
                    <div className='col-md-6 col-12 d-flex justify-content-between mt-md-0 mt-4'>
                        <div className='powered_by'>
                            <span className='powered_by_text'>powered by</span>
                            <img src={logopowered} />
                        </div>
                        <div className='d-flex'>
                            <a href="https://www.linkedin.com/company/blackhillfund" target="_blank" rel="noopener noreferrer">
                                <img src={linkdin} alt="LinkedIn" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
