import React, { useEffect, useState } from 'react'
import Home from './pages/Home'
import { FaChevronUp } from "react-icons/fa6";
import "./App.css"

const App = () => {
  const [showBottomBar, setShowBottomBar] = useState(false);

  const handleScroll = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollPosition >= 200) {
      setShowBottomBar(true);
    } else {
      setShowBottomBar(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {

    window.scrollTo(0, 0)

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };


  }, []);
  return (
    <>
      <Home />

      {showBottomBar && (
        <div className='bottom_bar' onClick={scrollToTop}>
          <div className='bottom_inner'>
            <FaChevronUp className='text-white' />
          </div>
        </div>
      )}
    </>
  )
}

export default App
