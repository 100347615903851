import React from 'react'
import "./Services.css"
import FoodAgriTechBlackTheme from "../../assets/Food&AgriTechBlackTheme.jpg"
import foodagree from "../../assets/Food&AgriTech.jpg"
import climatesBlackTheme from "../../assets/climatesBlackTheme.jpg"
import climate from "../../assets/climates.jpg"
import financial from "../../assets/financialImage.jpg"
import financialblackTheme from "../../assets/financialImageBlackTheme.jpg"
import consumerImageBlackTheme from "../../assets/Consumer_Background3BlackTheme.jpg"
import consumerImage from "../../assets/Consumer_Background3.jpg"
import SectorAgnosticImageBlackTheme from "../../assets/SectorAgnosticImageBlackTheme.png"
import SectorAgnosticImage from "../../assets/SectorAgnosticImage.png"
import Slider from 'react-slick'


const Services = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

    };

    return (
        <>
            <div className='section_one'></div>
            <section className='section services' id='focusarea'>


                <div className='container-fluid service_section py-5'>
                    <div className='px-0 pb-5'>

                        <div className='text-center heading_style pb-5 mb-3' data-aos-delay="500" data-aos="fade-down">
                            <span>Focus Areas</span>
                        </div>
                        <div className='d-none d-lg-block'>
                            <div className="d-flex justify-content-center">
                                <Slider {...settings}>
                                    <div className="col-4" data-aos="fade-right">
                                        <div className='service_card '>
                                            <div className='img_div'>
                                                <img src={FoodAgriTechBlackTheme} alt={FoodAgriTechBlackTheme} className="img_div_default" />
                                                <img src={foodagree} alt={foodagree} className="img_div_on_hover" />
                                            </div>
                                            <div className='srvice_content_div'>
                                                <div>
                                                    <h4 className="team-classic-name">
                                                        Food/Agri
                                                    </h4>
                                                    <div className="team-classic-status">
                                                        <ul>
                                                            <li>Food brands</li>
                                                            <li>
                                                                Evolved agritech
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-4" data-aos="zoom-in">
                                        <div className='service_card '>
                                            <div className='img_div '>
                                                <img src={climatesBlackTheme} alt={climatesBlackTheme} className="img_div_default" />
                                                <img src={climate} alt={climate} className="img_div_on_hover" />
                                            </div>
                                            <div className='srvice_content_div'>
                                                <div>
                                                    <h4 className="team-classic-name">
                                                        Climate
                                                    </h4>
                                                    <div className="team-classic-status">
                                                        <ul>
                                                            <li>Decarbonisation</li>
                                                            <li>Net Zero</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-4" data-aos="flip-left">
                                        <div className='service_card '>
                                            <div className='img_div '>
                                                <img src={financialblackTheme} alt={financialblackTheme} className="img_div_default" />
                                                <img src={financial} alt={financial} className="img_div_on_hover" />
                                            </div>
                                            <div className='srvice_content_div'>
                                                <div>
                                                    <h4 className="team-classic-name">
                                                        Financial Services
                                                    </h4>
                                                    <div className="team-classic-status">
                                                        <ul>
                                                            <li>Fintech Infra</li>
                                                            <li>Digital lending enablers</li>
                                                            <li>Green lending</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-4" data-aos="flip-left">
                                        <div className='service_card '>
                                            <div className='img_div'>
                                                <img src={consumerImageBlackTheme} alt={consumerImageBlackTheme} className="img_div_default" />
                                                <img src={consumerImage} alt={consumerImage} className="img_div_on_hover" />
                                            </div>
                                            <div className='srvice_content_div'>
                                                <div>
                                                    <h4 className="team-classic-name">
                                                        Consumer
                                                    </h4>
                                                    <div className="team-classic-status">
                                                        <ul>
                                                            <li>Brands</li>
                                                            <li>Digital Distribution</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4" data-aos="flip-left">
                                        <div className='service_card '>
                                            <div className='img_div'>
                                                <img src={SectorAgnosticImageBlackTheme} alt={SectorAgnosticImageBlackTheme} className="img_div_default" />
                                                <img src={SectorAgnosticImage} alt={SectorAgnosticImage} className="img_div_on_hover" />
                                            </div>
                                            <div className='srvice_content_div'>
                                                <div>
                                                    <h4 className="team-classic-name">
                                                        Sector agnostic opportunities (20% of funds)
                                                    </h4>
                                                    <div className="team-classic-status">
                                                        <ul>
                                                            <li>Services businesses</li>
                                                            <li>Smart manufacturing</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>

                            </div>
                        </div>

                        <div className='d-lg-none d-block'>
                            <div className='row'>
                                <div className="col-md-4 col-sm-6 col-12  mb-3" data-aos="fade-right">
                                    <div className='service_card '>
                                        <div className='img_div'>
                                            <img src={FoodAgriTechBlackTheme} alt={FoodAgriTechBlackTheme} className="img_div_default" />
                                            <img src={foodagree} alt={foodagree} className="img_div_on_hover" />
                                        </div>
                                        <div className='srvice_content_div'>
                                            <div>
                                                <h4 className="team-classic-name">
                                                    Food/Agri
                                                </h4>
                                                <div className="team-classic-status">
                                                    <ul>
                                                        <li>Food brands</li>
                                                        <li>
                                                            Evolved agritech
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12  mb-3" data-aos="zoom-in">
                                    <div className='service_card '>
                                        <div className='img_div '>
                                            <img src={climatesBlackTheme} alt={climatesBlackTheme} className="img_div_default" />
                                            <img src={climate} alt={climate} className="img_div_on_hover" />
                                        </div>
                                        <div className='srvice_content_div'>
                                            <div>
                                                <h4 className="team-classic-name">
                                                    Climate
                                                </h4>
                                                <div className="team-classic-status">
                                                    <ul>
                                                        <li>Decarbonisation</li>
                                                        <li>Net Zero</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12  mb-3" data-aos="flip-left">
                                    <div className='service_card '>
                                        <div className='img_div '>
                                            <img src={financialblackTheme} alt={financialblackTheme} className="img_div_default" />
                                            <img src={financial} alt={financial} className="img_div_on_hover" />
                                        </div>
                                        <div className='srvice_content_div'>
                                            <div>
                                                <h4 className="team-classic-name">
                                                    Financial Services
                                                </h4>
                                                <div className="team-classic-status">
                                                    <ul>
                                                        <li>Fintech Infra</li>
                                                        <li>Digital lending enablers</li>
                                                        <li>Green lending</li>
                                                        <li>Financial Inclusion</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-12  mb-3" data-aos="flip-left">
                                    <div className='service_card '>
                                        <div className='img_div'>
                                            <img src={consumerImageBlackTheme} alt={consumerImageBlackTheme} className="img_div_default" />
                                            <img src={consumerImage} alt={consumerImage} className="img_div_on_hover" />
                                        </div>
                                        <div className='srvice_content_div'>
                                            <div>
                                                <h4 className="team-classic-name">
                                                    Consumer
                                                </h4>
                                                <div className="team-classic-status">
                                                    <ul>
                                                        <li>Brands</li>
                                                        <li>Digital Distribution</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12  mb-3" data-aos="flip-left">
                                    <div className='service_card '>
                                        <div className='img_div'>
                                            <img src={SectorAgnosticImageBlackTheme} alt={SectorAgnosticImageBlackTheme} className="img_div_default" />
                                            <img src={SectorAgnosticImage} alt={SectorAgnosticImage} className="img_div_on_hover" />
                                        </div>
                                        <div className='srvice_content_div'>
                                            <div>
                                                <h4 className="team-classic-name">
                                                    Sector agnostic opportunities (20% of funds)
                                                </h4>
                                                <div className="team-classic-status">
                                                    <ul>
                                                        <li>Services businesses</li>
                                                        <li>Smart manufacturing</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Services
