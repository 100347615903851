import React, { useRef } from 'react';
import Header from '../components/header/Header';
import Banner from '../sections/banner/Banner';
import Teams from '../sections/teams/Teams';
import Contact from '../sections/contact/Contact';
import Footer from '../sections/footer/Footer';
import Map from '../sections/map/Map';
import Services from '../sections/services/Services';
import EsgEmpact from '../sections/ESG/EsgEmpact';
import InvestmentStrategy from '../sections/investmentStrategy/InvestmentStrategy';
import AboutUs from '../sections/aboutUs/AboutUs';
import Portfolio from '../sections/portfolio/Portfolio';

const Home = () => {


    return (
        <>
            <Header />
            <Banner />
            <AboutUs />
            <Services />
            <InvestmentStrategy />
            <EsgEmpact />
            <Teams />
            <Portfolio />
            <Contact />
            <Map />
            <Footer />
        </>
    );
}

export default Home;
