import React, { useEffect, useState } from 'react'
import "./Banner.css"
import blackHillDakodaImage from "../../assets/blackHillDakodaImage.png"
// import necklessQueen from "../../assets/necklessQueen.jpeg"
// import blackHillImage from "../../assets/blackHillImage.jpeg"
import bannerImage1 from "../../assets/bannerImage1.jpg"

import bannerImage3 from "../../assets/bannerImage3.jpg"
// import bannerImage4 from "../../assets/bannerImage4.jpg"
import bannerImage5 from "../../assets/bannerImage5.jpg"
// import bannerImage6 from "../../assets/bannerImage6.jpg"
import bannerImage7 from "../../assets/bannerImage7.jpg"
// import bannerImage8 from "../../assets/bannerImage8.jpg"
import bannerImage9 from "../../assets/bannerImage9.jpg"
// import bannerImage10 from "../../assets/bannerImage10.jpg"
import bannerImage11 from "../../assets/bannerImage11.jpg"




const Banner = () => {
    const [typeText, setTypeText] = useState("");
    useEffect(() => {
        const welcomeText = "Investing in the growth of India";
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            if (currentIndex <= welcomeText.length) {
                setTypeText(welcomeText.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(intervalId);
            }
        }, 80);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <section>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>

                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage3} alt={bannerImage3} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3'>{typeText}</h1>
                            </div>
                            <div>

                                <p className='fs-5'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={necklessQueen} alt={necklessQueen} className="w-100 default " />
                        </div>

                        <div class="text-center pt-2 text-white d-none d-md-block" data-aos-delay="500" data-aos="zoom-in">
                            <h1 className='mb-3'>{typeText}</h1>
                            <p>BlackHill is a leading growth fund employing both venture capital
                                and private equity strategies to generate returns and impact.
                                We follow a disciplined approach to investing and value creation.
                                In their previous avatars, the founders have produced stellar
                                investment returns. </p>
                        </div>
                    </div> */}
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={blackHillImage} alt={blackHillImage} className="w-100 on_hover" />
                        </div>
                        <div class="text-center pt-2 text-white d-none d-md-block" data-aos-delay="500" data-aos="zoom-in">
                            <h1 className='mb-3'>{typeText}</h1>
                            <p>
                                BlackHill is a leading growth fund employing both venture capital
                                and private equity strategies to generate returns and impact.
                                We follow a disciplined approach to investing and value creation.
                                In their previous avatars, the founders have produced stellar
                                investment returns
                            </p>
                        </div>
                    </div> */}
                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage1} alt={bannerImage1} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3 text-dark'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5 text-dark'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={blackHillDakodaImage} alt={blackHillDakodaImage} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage4} alt={bannerImage4} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3 text-warning'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5 text-warning'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their presvious avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div> */}

                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage5} alt={bannerImage5} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage6} alt={bannerImage6} className="w-100 on_hover" />
                        </div>
                        <div class="text-center pt-2 text-white d-none d-md-block" data-aos-delay="500" data-aos="zoom-in">
                            <h1 className='mb-3'>{typeText}</h1>
                            <p>
                                BlackHill is a leading growth fund employing both venture capital
                                and private equity strategies to generate returns and impact.
                                We follow a disciplined approach to investing and value creation.
                                In their previous avatars, the founders have produced stellar
                                investment returns
                            </p>
                        </div>
                    </div> */}

                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage7} alt={bannerImage7} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3 text-dark'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5 text-dark'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage8} alt={bannerImage8} className="w-100 on_hover" />
                        </div>
                        <div class="text-center pt-2 text-white d-none d-md-block" data-aos-delay="500" data-aos="zoom-in">
                            <h1 className='mb-3'>{typeText}</h1>
                            <p>
                                BlackHill is a leading growth fund employing both venture capital
                                and private equity strategies to generate returns and impact.
                                We follow a disciplined approach to investing and value creation.
                                In their previous avatars, the founders have produced stellar
                                investment returns
                            </p>
                        </div>
                    </div> */}
                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage9} alt={bannerImage9} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3 text-dark'>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5 text-dark'>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-aos="zoom-in"  data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage10} alt={bannerImage10} className="w-100 on_hover" />
                        </div>
                        <div class="text-center pt-2 text-white d-none d-md-block" data-aos-delay="500" data-aos="zoom-in">
                            <h1 className='mb-3'>{typeText}</h1>
                            <p>
                                BlackHill is a leading growth fund employing both venture capital
                                and private equity strategies to generate returns and impact.
                                We follow a disciplined approach to investing and value creation.
                                In their previous avatars, the founders have produced stellar
                                investment returns
                            </p>
                        </div>
                    </div> */}

                    <div className="carousel-item" data-aos="zoom-in" data-aos-duration="1000">
                        <div className='carousel-item-image'>
                            <img src={bannerImage11} alt={bannerImage11} className="w-100 on_hover" />
                        </div>
                        <div class="content_div pb-5 mb-5 text-center pt-2 text-white  d-flex flex-column  " data-aos-delay="500" data-aos="zoom-in">
                            <div>
                                <h1 className='mb-3 '>{typeText}</h1>
                            </div>
                            <div>
                                <p className='fs-5 '>BlackHill is a leading growth fund employing both venture capital
                                    and private equity strategies to generate returns and impact.
                                    We follow a disciplined approach to investing and value creation.
                                    In their previous avatars, the founders have produced stellar
                                    investment returns. </p>
                            </div>
                        </div>
                    </div>



                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    )
}

export default Banner
