import React, { useState } from 'react';
import "./Contact.css";
import { BiMessageDots } from "react-icons/bi";
import problackhillfundsServices from '../../services/problackhillfundsServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionName, setSelectedOptionName] = useState('');

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [modalFormData, setModalFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({});
    const [modalErrors, setModalErrors] = useState({});

    const setField = (formType, field, value) => {
        if (formType === 'main') {
            setFormData({
                ...formData,
                [field]: value,
            });
            setErrors({
                ...errors,
                [field]: "",
            });
        } else {
            setModalFormData({
                ...modalFormData,
                [field]: value,
            });
            setModalErrors({
                ...modalErrors,
                [field]: "",
            });
        }
    };

    const validateForm = (formType) => {
        const newErrors = {};
        const data = formType === 'main' ? formData : modalFormData;

        if (!data.name) {
            newErrors.name = "Name is required";
        } else if (!/^[a-zA-Z\s]+$/.test(data.name)) {
            newErrors.name = "Name should contain only letters and spaces";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!data.email) {
            newErrors.email = "Email is required";
        } else if (!emailRegex.test(data.email)) {
            newErrors.email = "Please enter a valid email address";
        }
        if (!selectedOption) {
            newErrors.subject = "Communication type is required";
        }

        if (formType === 'main') {
            setErrors(newErrors);
        } else {
            setModalErrors(newErrors);
        }

        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        setSelectedOptionName(event.target.name);
    };

    const handleSubmit = async (e, formType) => {
        e.preventDefault();
        if (validateForm(formType)) {
            const data = formType === 'main' ? formData : modalFormData;
            const { name, email, message } = data;
            const formsDetail = {
                clientName: "BLACKHILLFUND",
                name: name,
                email: email,
                msg: message,
                code: selectedOption,
                subject: selectedOptionName
            };

            try {
                const response = await problackhillfundsServices.sendEmail(formsDetail);
                console.log(response);
                toast("Email sent successfully");
            } catch (error) {
                console.log(error);

            }
        }
    };

    return (
        <>
            <section className='contact py-5 px-3' id='contact'>
                <div className='mb-5'>
                    <div className='text-center heading_style pb-3' data-aos-delay="500" data-aos="fade-down">
                        <span>Contact Us</span>
                    </div>
                    <div className='contact_section my-5 '>
                        <div className='row justify-content-between'>
                            <div className='col-lg-5 col-md-6 col-12'>
                                <div className='contact_left'>
                                    <div>
                                        <span className='contact_title'>LET’S GET IN TOUCH</span><br />
                                    </div>
                                    <div className='mt-2'>
                                        <span className='contact_heading'>CONTACT DETAILS</span>
                                    </div>
                                    <div className='mt-5 ms-3'>
                                        {/* <div className='d-flex text-white' data-aos="fade-right">
                                            <span className='company_name'>Phone</span>: &nbsp;
                                            <span className='number_style'>
                                                <a href="tel">(555)123-4567</a>
                                            </span>
                                        </div> */}
                                        <div className='d-flex mt-2 text-white' data-aos="fade-right">
                                            <span className='company_name'>Email</span>: &nbsp;
                                            <span className='number_style'>
                                                <a href="mailto:team@blackhillfund.com">team@blackhillfund.com</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-12'>
                                <form onSubmit={(e) => handleSubmit(e, 'main')}>
                                    <div>
                                        <input
                                            className='form-control contact_input_style'
                                            type='text'
                                            placeholder='Enter Name'
                                            value={formData.name}
                                            onChange={(e) => setField("main", "name", e.target.value)}
                                        />
                                        {errors?.name && (
                                            <div className="text-warning">
                                                {errors?.name}
                                            </div>
                                        )}
                                    </div>

                                    <div className='mt-4'>
                                        <input
                                            className='form-control contact_input_style'
                                            type='email'
                                            placeholder='Enter Email'
                                            value={formData.email}
                                            onChange={(e) => setField("main", "email", e.target.value)}
                                        />
                                        {errors?.email && (
                                            <div className="text-warning">
                                                {errors?.email}
                                            </div>
                                        )}
                                    </div>

                                    <div className='mt-4 mb-1'>
                                        <span className='type_text_style text-light'>Communication Type</span>
                                        <div className='mt-3'>
                                            <div className='d-flex position-relative'>
                                                <input
                                                    type='radio'
                                                    className='checkbox_style'
                                                    name='Submission of investment proposal'
                                                    value='1'
                                                    checked={selectedOption === '1'}
                                                    onChange={handleChange}
                                                />
                                                <div>
                                                    <span className='text-light ps-4 lable_style'>Submission of investment proposal</span><br />
                                                    <span className='text-light ps-4 lable_style d-flex'>(Send document to blackhillfund, please email &nbsp;
                                                        <strong className='text-white'>team@blackhillfund.com</strong>)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex position-relative'>
                                                <input
                                                    type='radio'
                                                    className='checkbox_style'
                                                    name='Interest as an LP/Contributor'
                                                    value='2'
                                                    checked={selectedOption === '2'}
                                                    onChange={handleChange}
                                                />
                                                <span className='text-light ps-4 lable_style'>Interest as an LP/Contributor</span>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex position-relative'>
                                                <input
                                                    type='radio'
                                                    className='checkbox_style'
                                                    name='General enquiries'
                                                    value='3'
                                                    checked={selectedOption === '3'}
                                                    onChange={handleChange}
                                                />
                                                <span className='text-light ps-4 lable_style'>General enquiries</span>
                                            </div>
                                        </div>
                                    </div>
                                    {errors?.subject && (
                                        <div className="text-warning">
                                            {errors?.subject}
                                        </div>
                                    )}
                                    <div className='mt-4'>
                                        <textarea
                                            className='form-control contact_input_style text_area'
                                            type='text'
                                            placeholder='Type message...'
                                            value={formData.message}
                                            onChange={(e) => setField("main", "message", e.target.value)}
                                        />
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <button
                                            className='btn contact_button shadow border-0'
                                            type='submit'
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='bottom_message' onClick={() => setShowModal(true)}>
                <BiMessageDots className='text-white' size={20} />
            </div>

            {showModal &&
                <div className='overlay'>
                    <div className={`popup ${showModal && 'modalClose'}`}>
                        <div className="content">
                            <div className='modal_header'>
                                <span>BlackHill Fund Management</span>
                                <div className='modal_header_inner'>
                                    <p className='m-0'>Hi! Let us know how we can help</p>
                                </div>
                                <a className="close" onClick={() => setShowModal(!showModal)}>&times;</a>
                            </div>
                            <div className='contact_inner p-4 mt-4'>
                                <form onSubmit={(e) => handleSubmit(e, 'modal')}>
                                    <div>
                                        <input
                                            className='form-control shadow-none bg-transparent text-dark border border-dark'
                                            type='text'
                                            placeholder='Enter Name'
                                            value={modalFormData.name}
                                            onChange={(e) => setField("modal", "name", e.target.value)}
                                        />
                                        {modalErrors?.name && (
                                            <div className="text-warning">
                                                {modalErrors?.name}
                                            </div>
                                        )}
                                    </div>

                                    <div className='mt-4'>
                                        <input
                                            className='form-control shadow-none bg-transparent text-dark border border-dark'
                                            type='email'
                                            placeholder='Enter Email'
                                            value={modalFormData.email}
                                            onChange={(e) => setField("modal", "email", e.target.value)}
                                        />
                                        {modalErrors?.email && (
                                            <div className="text-warning">
                                                {modalErrors?.email}
                                            </div>
                                        )}
                                    </div>

                                    <div className='mt-2 mb-1'>
                                        <span className='type_text_style text-dark'>Communication Type</span>
                                        <div className='mt-2'>
                                            <div className='d-flex position-relative'>
                                                <input
                                                    type='radio'
                                                    className='checkbox_style'
                                                    name='General enquiries'
                                                    value='3'
                                                    checked={selectedOption === '3'}
                                                    onChange={handleChange}
                                                />
                                                <span className='text-dark ps-4 lable_style'>General enquiries</span>
                                            </div>

                                        </div>
                                        {modalErrors?.subject && (
                                            <div className="text-warning">
                                                {modalErrors?.subject}
                                            </div>
                                        )}
                                    </div>
                                    <div className='mt-4'>
                                        <textarea
                                            className='form-control shadow-none text_area bg-transparent text-dark border border-dark'
                                            type='text'
                                            placeholder='Type message...'
                                            value={modalFormData.message}
                                            onChange={(e) => setField("modal", "message", e.target.value)}
                                        />
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <button
                                            className='btn contact_button shadow border-0'
                                            type='submit'
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <ToastContainer />
        </>
    );
};

export default Contact;
