import React from 'react'
import "./Map.css"
const Map = () => {
    return (
        <div className='map_style'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60345.980969208926!2d72.87969319999999!3d19.0362915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cfeb1f6c2593%3A0xe6823a79fe7aac61!2sBlackHill%20Fund!5e0!3m2!1sen!2sin!4v1724738910890!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default Map
