import api from "./api";

const sendEmail = (data) => {
    return api.post("/api/protEmail", data);
};


const proManagementServices = {
    sendEmail,
};

export default proManagementServices;
