import React from 'react'
import "./AboutUs.css"
const AboutUs = () => {
    return (
        <section id='about'>
            <div className='about_section py-5 d-flex flex-column gap-4 gap-sm-5'>
                <div className='text-center heading_style' data-aos-delay="500" data-aos="fade-down">
                    <span>About BlackHill Fund</span>
                </div>
                <div className='container about_content'>
                    <p>
                        BlackHill is a leading growth fund employing both venture capital and private equity strategies to generate returns and impact. We follow a disciplined approach to investing and value creation. In their previous avatars, the founders have produced stellar investment returns
                    </p>
                </div>

            </div>

        </section>
    )
}

export default AboutUs
