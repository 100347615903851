import axios from "axios";

const instance = axios.create({
  baseURL: "https://arrangeapi-x2j5lcflzq-em.a.run.app",


  headers: {
    "Content-Type": "application/json",
  },

  withCredentials: true,
});



export default instance;
