import React, { useState } from 'react';
import "./Header.css";
import logo from "../../assets/logos.jpg";

import { Link } from 'react-scroll';
const Header = () => {



    const sections = [
        { id: 'about', label: 'About Us' },
        { id: 'focusarea', label: 'Focus Areas' },
        { id: 'investmentStrategy', label: 'Investment Strategy' },
        { id: 'esg', label: 'ESG' },
        { id: 'team', label: 'Team' },
        { id: 'portfolio', label: 'Portfolio' },
        { id: 'contact', label: 'Contact Us' },
    ];


    return (
        <div className='sticky-top shadow'>
            <nav className="navbar navbar-expand-lg navbar-light header_bg px-3 py-0">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} className='logo_style' alt="Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse d-none d-lg-flex" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-lg-auto my-4 my-lg-0">
                            {sections.map(section => (
                                <li className="nav-item my-2 my-lg-0" key={section.id}>
                                    <Link
                                        activeClass="active_nav"
                                        to={section.id}
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={0}
                                    >
                                        {section.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav >

            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                        <img src={logo} className='offcanvas_logo_style' alt="Logo" />
                    </h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav">
                        {sections.map(section => (
                            <li className="nav-item my-1" key={section.id} data-bs-dismiss="offcanvas">
                                <Link
                                    activeClass="active_nav"
                                    to={section.id}
                                    spy={true}
                                    smooth={true}
                                    offset={-50}
                                    duration={0}
                                >
                                    {section.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div >
    );
};

export default Header;
