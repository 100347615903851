import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Portfolio.css";
import comingSoonImageBlackTheme from "../../assets/comingSoonImageBlackTheme.jpg";
import comingSoonImage from "../../assets/comingSoonImage.jpg";

const Portfolio = () => {


    return (
        <section className='portfolio py-3' id="portfolio">
            <div className='text-center heading_style my-4 pb-3'>
                <span data-aos="fade-down">Portfolio</span>
            </div>
            <div className='portfolio_section'>

                <img src={comingSoonImageBlackTheme} alt={comingSoonImageBlackTheme} className="img_div_default" />
                <img src={comingSoonImage} alt={comingSoonImage} className="img_div_on_hover" />

            </div>
        </section>
    );
}

export default Portfolio;
