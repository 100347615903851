import React from 'react'
import "./InvestmentStrategy.css"
const InvestmentStrategy = () => {
    return (
        <>
            <div className='section_two '></div>

            <section id='investmentStrategy'>
                <div className='investmentStrategy py-4'>
                    <div className='text-center heading_style my-4 ' data-aos="fade-right">
                        <span>Investment Strategy</span>
                    </div>

                    <div className='container-fluid pt-5 pb-4 px-5'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 col-12 mb-4' data-aos="zoom-in">
                                <div className='detail_card p-3'>
                                    <h2>
                                        Companies/SMEs operating in Focus Areas
                                    </h2>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 mb-4' data-aos="zoom-in">
                                <div className='detail_card p-3'>
                                    <h2>Highly scalable business models</h2>

                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 mb-4' data-aos="zoom-in">
                                <div className='detail_card p-3'>
                                    <h2>
                                        High emphasis on ESG/Net Zero in business models
                                    </h2>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 mb-4' data-aos="zoom-in">
                                <div className='detail_card p-3'>
                                    <h2>
                                        Revenue of USD 10 million plus
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default InvestmentStrategy
